let _paq = window._paq = window._paq || [];
_paq.push(['setRequestMethod', 'POST']);
_paq.push(['trackPageView']);
_paq.push(['enableLinkTracking']);
(function () {
    let u = "https://w2g-mtrx.w2g.tv/";
    _paq.push(['setTrackerUrl', u + 'w2mtrx']);
    _paq.push(['setSiteId', '4']);
    let d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
    g.async = true; g.src = 'https://w2gmtrx.b-cdn.net/q42.js'; s.parentNode.insertBefore(g, s);
})();