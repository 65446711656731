
import { W2gDataObject } from './lib/w2gdataobject';
import { W2gBind } from './lib/w2gbind';
import * as markerjs2 from './lib/markerjs2';
import * as cropro from './lib/cropro';
import {} from './lib/mtrx'

let dragCounter = 0, orgImg = null, counter = 1, apiurl = window.location.hostname === "localhost" ? "https://gateway.snipzero.com" : "https://gateway.snipzero.com"

let damnobj = {
    screenshot: "",
    shareCode: null,
    shareOpen: false,
    takeScreenshot: async function () {
        const canvas = document.createElement("canvas");
        const video = document.createElement("video");
        video.muted = true;
        const captureStream = await navigator.mediaDevices.getDisplayMedia({
            logicalSurface: true,
            audio: false,
            video: true
        });
        video.srcObject = captureStream;
        await video.play();
        video.pause();
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);
        const frame = canvas.toDataURL("image/png");
        captureStream.getTracks().forEach(track => track.stop());
        orgImg = frame;
        this.screenshot = frame;
    },
    crop: function () {
        let img = document.getElementById('screenshot');
        let cropArea = new cropro.CropArea(img);
        cropArea.addRenderEventListener(dataUrl => {
            ssz.screenshot = dataUrl;
        });
        cropArea.renderAtNaturalSize = true;
        cropArea.renderImageType = 'image/png';
        cropArea.zoomToCropEnabled = false;
        cropArea.displayMode = 'popup';
        cropArea.styles.settings.toolbarCloseButtonStyleColorsClassName = "fill-red-700 marker-bg-hover";
        cropArea.styles.settings.toolbarOkButtonStyleColorsClassName = "fill-green-500 marker-bg-hover";
        cropArea.styles.settings.canvasBackgroundColor = "#374151";
        cropArea.show();
    },
    draw: function () {
        let img = document.getElementById('screenshot'),
            markerArea = new markerjs2.MarkerArea(img);
        markerArea.addEventListener('render', event => {
            ssz.screenshot = event.dataUrl;
        });
        markerArea.renderAtNaturalSize = true;
        markerArea.renderImageType = 'image/png';
        markerArea.settings.displayMode = 'popup';
        markerArea.uiStyleSettings.okButtonColor = '#a3e635';
        markerArea.uiStyleSettings.closeButtonColor = '#b91c1c';
        markerArea.uiStyleSettings.canvasBackgroundColor = "#374151";

        markerArea.show();
    },
    reset: function () {
        ssz.screenshot = orgImg;
    },
    download: function () {
        let link = document.createElement('a');
        link.download = "snipzero-com.png";
        link.href = ssz.screenshot;
        link.click();
        counter++;
    },
    copy: async function () {
        const blob = await (await fetch(ssz.screenshot)).blob();
        navigator.clipboard.write([new ClipboardItem({
            [blob.type]: blob
        })]);
    },
    upload: function () {
        if (ssz.shareCode === null) {
            let img = new Image();
            img.addEventListener("load", function () {
                let canvas = document.createElement("canvas");
                canvas.width = img.naturalWidth;
                canvas.height = img.naturalHeight;
                canvas.getContext("2d").drawImage(img, 0, 0);
                canvas.toBlob(async function (blob) {
                    let form = new FormData();
                    form.append("image", blob);
                    let response = await fetch(apiurl + "/upload", {
                        method: "post",
                        body: form
                    });
                    ssz.shareCode = "https://snipzero.com/snip.html?s=" + await response.text();
                }, "image/jpeg", 0.85);
            }.bind(this));
            img.src = this.screenshot;
        }
        this.shareOpen = true;
    },
    copyShare: function() {
        navigator.clipboard.writeText(this.shareCode);
    }
};

let ssz = W2gDataObject(damnobj);

ssz._addHandler("screenshot", function () {
    ssz.shareCode = null;
});

new W2gBind(ssz, "body");

